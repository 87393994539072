.animate__fadeInDown {
  animation-name: fadeInDown;
  animation-timing-function: ease;
}

.animated {
  animation-duration: .5s;
  animation-fill-mode: both
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
