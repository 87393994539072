@import './_variables.scss';
@import './_mixins.scss';
@import './_reset.scss';
@import './_animations.scss';
@import './_fonts.scss';
@import './_icons.scss';


//.h1, h1 {
//  @include h1;
//}

.h2, h2 {
  @include h2;
}

.h3, h3 {
  @include h3;
}
