* {
  font-family: Onest, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@font-face {
  font-family: 'Onest';
  src: local('Onest Bold 1602'), local('OnestBold1602'),
  url('../assets/fonts/OnestBold1602-hint.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}