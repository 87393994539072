@import "../../styles/_variables.scss";

.logo {
  width: 100%;
  height: 100%;

  :global {
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
