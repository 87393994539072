* {
  box-sizing: border-box;
}

body {
  max-width: 100%;
  margin: 0;
  font-family: Onest, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #05040D;
  color: #FFFFFF;
}

.active {
  overflow: hidden;
}

/*@font-face {*/
/*  font-family: 'Onest';*/
/*  src: local('Onest'), url(assets/fonts/OnestBlack1602-hint.woff) format('woff');*/
/*  font-weight: 700;*/
/*}*/

@font-face {
  font-family: 'Onest';
  src: local('Onest'), url(assets/fonts/OnestRegular1602-hint.woff) format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Onest';
  src: local('Onest'), url(assets/fonts/OnestThin1602-hint.woff) format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'Onest';
  src: local('Onest'), url(assets/fonts/OnestMedium1602-hint.woff) format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Onest';
  src: local('Onest'), url(assets/fonts/OnestLight1602-hint.woff) format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Onest';
  src: local('Onest'), url(assets/fonts/OnestExtraBold1602-hint.woff) format('woff');
  font-weight: 800;
}
