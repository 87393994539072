@import "../../styles/_variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/base.scss";


.footer {
  @extend %container;
  @extend %sectionPadding;
  display: flex;
  flex-direction: column;

  height: 106px;
  background: $footer-bg;
  padding-top: 16px;
  padding-bottom: 16px;

  .footerInner {
    display: flex;
    align-items: center;
    justify-content: space-between;


  }

  .topBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    @include laptop {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;
    }

    .dualFiLogo {
      @include  laptop {
        margin-bottom: 24px;
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include laptop {
        width: 100%;
      }

      @include tablet {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      font-weight: bold;
      font-size: 16px;
      color: $text-color-primary;

      .links {
        display: flex;
        flex-direction: row;
        @include tablet {
          width: 100%;
          //margin-top: 32px;
          margin-bottom: 24px;
          //text-align: center;
        }

        .col {
          display: flex;
          @include tablet {
            width: calc(50% - 8px);
            flex-direction: column;
            &:first-child {
              margin-right: 16px;
            }
          }
        }

        a {
          color: $text-color-primary;
          text-decoration: none;
          margin-right: 24px;
          @include tablet {
            height: 26px;
            margin-bottom: 8px;
            margin-right: 0;
          }
        }
      }
    }
  }

  .bottomBlock {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $text-color-tertiary;
  }
}
