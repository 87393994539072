@import "../../styles/_variables.scss";
@import "../../styles/_icons.scss";
@import "../../styles/_mixins.scss";
@import "../../styles/base.scss";

.heroSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .container {
    @extend %container;
  }

  .sectionWrapper {
    display: flex;
    flex-direction: row;

    @include desktop {
      padding: 0 24px;
    }

    @include laptop {
      padding: 0 40px;
    }

    @include tablet {
      flex-direction: column;
      padding: 0 16px;
    }


    .grid {
      @extend %grid;

      .cell.illustrationCell {
        align-items: center;
        justify-content: flex-start;

        @include desktop {
          justify-content: flex-end;
        }

        @include tablet {
          justify-content: center;
        }
      }
    }

    .textBlock {
      padding-left: 194px;
      margin-top: 170px;


      @include desktop {
        margin-top: 102px;
        padding-left: 0;
      }

      @include tablet {
        margin-top: 40px;
      }

      .title {
        font-size: 68px;
        font-weight: 800;
        line-height: 76px;
        color: $text-color-primary;
        margin-bottom: 24px;

        @include laptop {
          font-size: 48px;
          font-weight: 800;
          line-height: 52px;
        }
      }

      .description {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        color: $text-color-secondary;
        margin-bottom: 48px;

        @include laptop {
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0px;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 48px;

        @include laptop {
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 40px;
        }

        .launchBtn {
          max-width: 167px;

          @include laptop {
            margin-bottom: 16px;
          }
          @include tablet {
            margin-bottom: 8px;
            max-width: 100%;
          }
        }

        .readDocsBtn {
          margin-left: 16px;
          max-width: 261px;

          img {
            margin-left: 6px;
            margin-bottom: 16px;
          }

          @include laptop {
            margin-left: 0;
          }

          @include tablet {
            max-width: 100%;
          }
        }
      }

      .builtOn {
        display: flex;
        flex-direction: row;

        .builtOnText {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

          color: $text-color-tertiary;
        }

        .solanaLogo {
          margin-left: 8px;
        }
      }

      .winners {
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        .winnersText {
          margin-top: 25px;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

          color: $text-color-tertiary;
        }

        .summerLogo {
          margin-left: 8px;
          max-width: 25%;
          max-height: 25%;
        }
      }
    }

    .illustration {
      max-width: 100%;

      @include desktop {
        max-width: 464px;

      }

      @include laptop {
        max-width: 336px;
      }

      @include mobile {
        max-width: 100%;
      }

    }

  }
}
