@import "../../styles/_variables.scss";
@import "../../styles/_icons.scss";
@import "../../styles/_mixins.scss";
@import "../../styles/base.scss";

.callToActionSection {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 80px;
  @extend %container;
  @extend %sectionPadding;
  padding-top: 0;
  padding-bottom: 0;

  @include laptop {
    max-height: 100%;
  }

  .callToActionSectionInner {
    display: flex;
    justify-content: center;
    width: 100%;
    border: 4px solid #D6F4FE;
    border-radius: 48px;
    margin-top: 284px;
    padding: 25px 78px;

    @include desktop {
      margin-top: 216px;
      padding: 25px 56px;
    }

    @include laptop {
      padding: 45px 40px;
      margin-top: 164px;
    }

    @include mobile {
      margin-top: 130px;
    }

    @include tablet {
      align-items: center;
      flex-direction: column;
      padding: 40px 32px;
      border: 2px solid #D6F4FE;
      border-radius: 40px;
    }

    .imageContainer {
      display: flex;
      max-width: 375px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      @include desktop {
        max-width: 271px;
      }

      @include laptop {
        max-width: 235px;
      }

      @include tablet {
        max-width: 195px;
        margin-bottom: 24px;
      }

    }

    .textContainer {
      display: flex;
      width: 100%;
      max-width: 664px;
      flex-direction: column;
      justify-content: center;
      margin-left: 77px;

      p {
        display: flex;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        align-items: center;
        width: 100%;
      }

      @include desktop {
        margin-left: 73px;

      }

      @include laptop {

        p {
          font-size: 32px;
          line-height: 36px;
        }
      }

      @include tablet {
        margin: 0;

        p {
          text-align: center;
          font-size: 30px;
          line-height: 34px;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 32px;

        .launchBtn {
          max-width: 167px;
          width: 100%;

          @include laptop {
            margin-bottom: 16px;
          }

          @include tablet {
            max-width: 100%;
          }
        }

        .readDocsBtn {
          margin-left: 16px;
          max-width: 261px;
          width: 100%;
          white-space: nowrap;

          img {
            margin-left: 6px;
            margin-bottom: 16px;
          }

          @include laptop {
            margin: 0 0 16px;
          }

          @include tablet {
            margin: 0;
            max-width: 100%;
          }
        }

        @include laptop {
          flex-direction: column;
          margin-top: 24px;
        }

        @include tablet {
          margin-top: 24px;
          max-width: 100%;
        }
      }
    }
  }
}

